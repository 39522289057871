import React, { memo, useEffect, useState, forwardRef, useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { serverURL } from '../constants';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { FormHelperText, TableContainer, Input, InputLabel, FormControl, 
CircularProgress, Checkbox, FormControlLabel, Button, Dialog, DialogTitle,
DialogActions, DialogContent, DialogContentText, TextField } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { logs } from '../App';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import Document from '@material-ui/icons/Description';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ExitToApp from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MailIcon from '@material-ui/icons/Mail';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import BrushIcon from '@material-ui/icons/Brush';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import ColorizeIcon from '@material-ui/icons/Colorize';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import { stringify } from 'qs';

const tableIcons = {
  CloudDone: forwardRef((props, ref) => <CloudDoneIcon {...props} color="primary" ref={ref} />),
  CloudDownload: forwardRef((props, ref) => <CloudDownloadIcon {...props} color="error" ref={ref} />),
  AssignmentReturned: forwardRef((props, ref) => <AssignmentReturnedIcon {...props} color="error" ref={ref} />),
  AssignmentTurned: forwardRef((props, ref) => <AssignmentTurnedInIcon {...props} color="primary" ref={ref} />),

  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ExitToApp: forwardRef((props, ref) => <ExitToApp {...props} ref={ref} />),
  PersonAddIcon: forwardRef((props, ref) => <PersonAddIcon {...props} ref={ref} />),
  DescriptionIcon: forwardRef((props, ref) => <DescriptionIcon {...props} ref={ref} />),
  MailIcon: forwardRef((props, ref) => <MailIcon {...props} ref={ref} />)
};

const useStyles = makeStyles(theme => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    API: {
      background: '#eee',
      padding: 10,
      marginBottom: 10
    },
    input: {
      paddingLeft: 10,
      paddingBottom: 20,
      width: '100%'
    },
    delete: {
      float: "right", 
      marginTop: -50
    }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CanConnectSQL(props) {
  const [status, setStatus] = useState("loading");

  fetch(serverURL+'/testSQL/'+props.id, { mode: 'no-cors'})
  .then(
    (result) => {
      console.log(serverURL+'/testSQL/'+props.id,result);
      if(result.ok)
        setStatus("ok");
      else 
       setStatus("err");
    },
    (error) => {
      console.log("err",error);
      setStatus("err");
    }
  )

  return (
    <span>
      {status == "loading" &&
        <CircularProgress style={{width: 20, height: 20, position: "absolute", marginLeft: 6, marginTop: 6}} />
      }
      {status == "err" &&
        <Remove style={{width: 20, height: 20, position: "absolute", marginLeft: 6, marginTop: 6, color: 'red'}} />
      }
      {status == "ok" &&
        <Check style={{width: 20, height: 20, position: "absolute", marginLeft: 6, marginTop: 6}} />
      }
    </span>
  )
}

function IsValid(props) {
  const [status, setStatus] = useState("loading");

  fetch(props.URL, { mode: 'no-cors'})
  .then(res => res)
  .then(
    (result) => {
      setStatus("ok");
      //console.log(props.URL+" fetched")
    },
    (error) => {
      //console.log("err",error);
      setStatus("err");
    }
  )

  return (
    <span>
      {status == "loading" &&
        <CircularProgress style={{width: 12, height: 12, position: "absolute", marginLeft: 6, marginTop: 6}} />
      }
      {status == "err" &&
        <Remove style={{width: 12, height: 12, position: "absolute", marginLeft: 6, marginTop: 6, color: 'red'}} />
      }
      {status == "ok" &&
        <Check style={{width: 12, height: 12, position: "absolute", marginLeft: 6, marginTop: 6}} />
      }
    </span>
  )
}

const Servers = memo(props => {
    const classes = useStyles();
    const servers = props.ServerList;
    const [alertMessage, setAlertMessage] = React.useState("Success");
    const [severity, setSeverity] = React.useState("success");
    const [open, setOpen] = React.useState(false);
    const [updated, setUpdated] = useState(false);
    const [showSQL, setShowSQL] = useState(false);
    const [title, setTitle] = useState("SERVERS LIST (0)");
    const [plansUpdated, setPlansUpdated] = useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [opend, setOpend] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('-');
    const [logsList, setLogsList] = useState("");
    const [hiddenField, setHiddenField] = useState(true);
    const [editor, setEditor] = useState("");
    const [dataEditor, setDataEditor] = useState(null);
    const valueRef = useRef('');

    React.useEffect(() => {
      setTitle("SERVERS LIST ("+servers.length+" CLIENTS, "+servers.length*2+" SERVERS)");      
    }, [servers]);

    const handleClickOpend = (scrollType) => () => {
      setOpend(true);
      setScroll(scrollType);
    };
  
    const handleClosed = () => {
      setOpend(false);
    };
  
    const descriptionElementRef = React.useRef(null);

    const getLogs = () => {
      axios({
        method: 'get',
        url: serverURL+'/getLogs',
        params: {
          access_token: props.authToken
        }
      }).then(function (resp) {
        console.log("/getLogs",resp);
        console.log("data:",resp.data);
        if(resp.status == 200){
          const response = resp.data;
          setLogsList(response.logs);
        }
      }).catch(function (error) {
        console.log("err getServers",error);
      });
    };

    React.useEffect(() => {
      if (opend) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [opend]);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    React.useEffect(() => {
      getLogs();
    }, []);

    return(
        <TableContainer component={Paper} style={{padding: 20}}>
          <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
            {alertMessage}
            </Alert>
          </Snackbar>
          <h2>Servers Manager</h2>
          <button onClick={()=>{setHiddenField(!hiddenField);}} style={{position: 'absolute', marginLeft: 250, marginTop: -50}}>
            {hiddenField?<VisibilityIcon fontSize="small"/>:<VisibilityOffIcon fontSize="small"/>} <span style={{fontSize: 20}}>Display additonnal fields</span>
          </button>
        <div className={classes.API}>
          { !servers.length &&
            <CircularProgress />
          }
          <MaterialTable
            options={{
              pageSize: 20,
              pageSizeOptions: [5, 10, 20, 30 ,50, 75, 100 ],
              toolbar: true,
              paging: true
            }}
            title={title}
            columns={[
              { title: 'Server Name', field: 'name' },
              { title: 'Individuals', field: 'frontIndividuals', type: 'string', render: rowData => <span style={{fontSize: 11}}><a target="_blank" href={rowData.frontIndividuals}>{rowData.frontIndividuals}</a><IsValid URL={rowData.frontIndividuals}/> {/*<a target="_blank" href={rowData.frontCorporate}>{rowData.frontCorporate}</a><IsValid URL={rowData.frontCorporate}/>*/}</span> },
              { title: 'Corporate', field: 'frontCorporate', type: 'string', render: rowData => <span style={{fontSize: 11}}><a target="_blank" href={rowData.frontCorporate}>{rowData.frontCorporate}</a><IsValid URL={rowData.frontCorporate}/></span> },
              { title: 'Backend', field: 'backendCorporate', render: rowData => 
              <span style={{fontSize: 11}}>
                <div>
                  {rowData.backendIndividuals &&
                    <span>
                      <a target="_blank" href={rowData.backendIndividuals.replace('/api', '/explorer')}>{rowData.backendIndividuals}</a><IsValid URL={rowData.backendIndividuals}/>
                    </span>
                  }
                </div>
                {rowData.backendCorporate &&
                  <span>
                    <a target="_blank" href={rowData.backendCorporate.replace('/api', '/explorer')}>{rowData.backendCorporate}</a><IsValid URL={rowData.backendCorporate}/>
                  </span>
                }
              </span> },
              { title: 'Theme color', field: 'color', type: 'string',  render: rowData => <span style={{color: rowData.color, fontWeight: 'bold'}}>{rowData.color} <button onClick={()=>{ window.open('https://www.webfx.com/web-design/color-picker/'+rowData.color.substring(1), '_blank'); }}><ColorizeIcon fontSize="small"/></button></span> },
              { title: 'Logo', field: 'logo', type: 'string', render: rowData => <span>{rowData.logo?<img src={rowData.logo} width={60} />:<span>NO LOGO</span>}</span> },
              { title: 'Notification mail', field: 'mail', type: 'string', hidden: hiddenField },
              { title: 'IP', field: 'ip', type: 'string' },
              { title: 'Facebook', field: 'mail', type: 'string', hidden: hiddenField },
              { title: 'LinkedIn', field: 'mail', type: 'string', hidden: hiddenField },
              { title: 'YouTube', field: 'mail', type: 'string', hidden: hiddenField },
              { title: 'Twitter', field: 'mail', type: 'string', hidden: hiddenField },
              { title: 'Phone', field: 'phone', type: 'string', hidden: hiddenField },
              //{ title: 'masterCredentialsEmail', field: 'masterCredentialsEmail', type: 'string', hidden: hiddenField },
              //{ title: 'masterCredentialsPassword', field: 'masterCredentialsPassword', type: 'string', hidden: hiddenField },
              { title: 'Rewards', field: 'rewards', type: 'string', hidden: hiddenField },
              { title: 'corporateSignUp', field: 'corporateSignUp', type: 'string', hidden: hiddenField },
              { title: 'mobile', field: 'mobile', type: 'string', hidden: hiddenField }
            ]}
            data={servers}
            icons={{...tableIcons,
              Add: forwardRef((props, ref) => 
              <button style={{padding: 2}}>
                <AddBox {...props} ref={ref} style={{ fontSize: 35, color: 'green' }} />
                <div style={{fontSize: 16}}>Add server</div>
              </button>
              )
            }}
            actions={[
              rowData => ({
                icon: tableIcons.DescriptionIcon,
                tooltip: 'PDF templates',
                onClick: (event, rowData) => {
                  setOpend(true);
                  setEditor(rowData.templatePDF);
                  let data = rowData;
                  data.type = "templatePDF";
                  setDataEditor(data);
                }
              }),
              rowData => ({
                icon: tableIcons.MailIcon,
                tooltip: 'Email templates',
                onClick: (event, rowData) => {
                  setOpend(true);
                  setEditor(rowData.templateEmail);
                  let data = rowData;
                  data.type = "templateEmail";
                  setDataEditor(data);
                }
              }),
              rowData => ({
                icon: CardMembershipIcon,
                tooltip: 'Certificate templates',
                onClick: (event, rowData) => {
                  setOpend(true);
                  setEditor(rowData.certificatePDF);
                  let data = rowData;
                  data.type = "certificatePDF";
                  setDataEditor(data);
                }
              })
            ]}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    axios({
                      method: 'put',
                      url: serverURL+'/api/servers',
                      params: {
                        access_token: props.authToken
                      },
                      data: {                        
                        name: newData.name,
                        frontIndividuals: newData.frontIndividuals,
                        frontCorporate: newData.frontCorporate,
                        backendCorporate: newData.backendCorporate,
                        mail: newData.mail,
                        ip: newData.ip,
                        logo: newData.logo,
                        color: newData.color
                      }
                    }).then(function (resp) {
                      setSeverity("success");
                      setAlertMessage("Saved");
                      props.onUpdate();
                    }).catch(function (error) {
                      /*console.log("err",error);
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);*/
                    });
                    props.onUpdate();

                    resolve();
                  }, 600)
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    axios({
                      method: 'patch',
                      url: serverURL+'/api/servers/'+oldData.id,
                      params: {
                        access_token: props.authToken
                      },
                      data: {
                        name: newData.name,
                        frontIndividuals: newData.frontIndividuals,
                        frontCorporate: newData.frontCorporate,
                        backendCorporate: newData.backendCorporate,
                        mail: newData.mail,
                        ip: newData.ip,
                        logo: newData.logo,
                        color: newData.color
                      }
                    }).then(function (resp) {
                      logs("update",'server details '+newData.name+" "+JSON.stringify({
                        name: oldData.name,
                        frontIndividuals: oldData.frontIndividuals,
                        frontCorporate: oldData.frontCorporate,
                        backendCorporate: oldData.backendCorporate,
                        mail: oldData.mail,
                        ip: oldData.ip,
                        logo: oldData.logo,
                        color: oldData.color
                      }));
                      setSeverity("success");
                      setAlertMessage("Saved");
                      props.onUpdate();
                    }).catch(function (error) {
                      /*console.log("err",error);
                      setSeverity("error");
                      setAlertMessage("error");
                      setOpen(true);*/
                    })

                    resolve();
                  }, 600)
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    logs("delete",'servers '+JSON.stringify(oldData));
                    /*const dataDelete = [...data];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    setServers([...dataDelete]);
                    */
                    resolve()
                  }, 1000)
                }),
            }}
          />
          <Dialog
            open={opend}
            onClose={handleClosed}
            maxWidth='lg'
            fullWidth={true}
            scroll={scroll}
            style={{ width: window.innerWidth }}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">Editeur de template - {dataEditor!==null?dataEditor.type:''} {dataEditor!==null?dataEditor.name:''}</DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                //style={{ width: window.innerWidth-100 }}
                tabIndex={-1}
              >
                <textarea
                  id="outlined-multiline-static"
                  style={{
                    width: '100%',
                    background: 'url('+require('../images/lines.png')+')',
                    backgroundAttachment: 'local',
                    backgroundRepeat: 'no-repeat',
                    paddingLeft: 35,
                    paddingTop: 12,
                    borderColor: '#ccc'
                  }}
                  multiline
                  rows={45}
                  defaultValue={editor}
                  variant="outlined"
                  ref={valueRef}
                ></textarea>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{
                setOpend(false);
                console.log("valueRef",valueRef);
                let value = valueRef.current.value;
                console.log(value);
                console.log("type",dataEditor.type);
                console.log("id",dataEditor.id);

                let data = {
                  templateEmail: value
                };

                logs("update","server template "+dataEditor.name);

                if(dataEditor.type==="templatePDF") {
                  data = { templatePDF: value };
                  logs("templatePDF",dataEditor.templatePDF);
                } 
                else if (dataEditor.type==="certificatePDF") {
                  data = { certificatePDF: value };
                  logs("certificatePDF",dataEditor.certificatePDF);
                }
                 else logs("templateEmail",dataEditor.templateEmail);

                setTimeout(() => {
                  axios({
                    method: 'patch',
                    url: serverURL+'/api/servers/'+dataEditor.id,
                    params: {
                      access_token: props.authToken
                    },
                    data: data
                  }).then(function (resp) {
                    console.log("resp:",resp);
                    console.log("reload!");
                    window.location.reload(true);
                  }).catch(function (error) {
                    console.log("err",error);
                  })
                }, 500);
              }} 
              startIcon={<SaveIcon />}
              color="primary" variant="contained">
                SAVE
              </Button>
                
              <Button onClick={()=>setOpend(false)} color="secondary" variant="contained">
                CLOSE
              </Button>
            </DialogActions>
          </Dialog>
          <div>
            <div>IP SERVERS LOGS:</div>
            <textarea value={logsList} style={{
            width: '100%', 
            height: 250, 
            }}>
            </textarea>
          </div>
        </div>
      </TableContainer>
    )
});

export default Servers;