import React, { memo, useEffect, useState, forwardRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { serverURL } from '../constants';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import { FormHelperText, TableContainer, Input, InputLabel, FormControl, 
CircularProgress, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { logs } from '../App';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import Document from '@material-ui/icons/Description';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ExitToApp from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MailIcon from '@material-ui/icons/Mail';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import BrushIcon from '@material-ui/icons/Brush';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const tableIcons = {
  CloudDone: forwardRef((props, ref) => <CloudDoneIcon {...props} color="primary" ref={ref} />),
  CloudDownload: forwardRef((props, ref) => <CloudDownloadIcon {...props} color="error" ref={ref} />),
  AssignmentReturned: forwardRef((props, ref) => <AssignmentReturnedIcon {...props} color="error" ref={ref} />),
  AssignmentTurned: forwardRef((props, ref) => <AssignmentTurnedInIcon {...props} color="primary" ref={ref} />),

  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  ExitToApp: forwardRef((props, ref) => <ExitToApp {...props} ref={ref} />),
  PersonAddIcon: forwardRef((props, ref) => <PersonAddIcon {...props} ref={ref} />)
};

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    API: {
      background: '#eee',
      padding: 10,
      marginBottom: 10
    },
    input: {
      paddingLeft: 10,
      paddingBottom: 20,
      width: '100%'
    },
    delete: {
      float: "right", 
      marginTop: -50
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const PlansUpdater = memo(props => {
    const classes = useStyles();
    const [servers, setServers] = React.useState([]);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const [alertMessage, setAlertMessage] = React.useState("Success");
    const [severity, setSeverity] = React.useState("success");
    const [open, setOpen] = React.useState(false);
    const [updated, setUpdated] = useState(false);
    const [plansUpdated, setPlansUpdated] = useState(false);
    const [value, setValue] = React.useState(0);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const getServers = () => {
      axios({
        method: 'get',
        url: serverURL+'/api/servers',
        params: {
          access_token: props.authToken
        }
      }).then(function (resp) {
        console.log("/api/servers",resp);
        if(resp.status == 200){
          const response = resp.data;
          setServers(response);
        }
      }).catch(function (error) {
        console.log("err",error);
      });
    };

    useEffect(() => {
      getServers();
    }, []);

    return(
        <TableContainer component={Paper} style={{padding: 20}}>
          <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity}>
            {alertMessage}
            </Alert>
          </Snackbar>
          <h2>Plans Updater</h2>
        <div className={classes.root}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            <Tab label="1. Création d'une compagnie" {...a11yProps(0)} />
            <Tab label="2. Ajout d'un logo de compagnie" {...a11yProps(1)} />
            <Tab label="3. Ajout d'un plan" {...a11yProps(2)} />
            <Tab label="4. Upload d'un tableau de garantie" {...a11yProps(4)} />
            <Tab label="5. Upload d'un tableau des prix" {...a11yProps(5)} />
            <Tab label="6. Documentation" {...a11yProps(6)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <h2>Création d'une compagnie</h2>
            Développement en cours...
          </TabPanel>
          <TabPanel value={value} index={1}>
            <h2>Ajout d'un logo de compagnie</h2>
            Développement en cours...
          </TabPanel>
          <TabPanel value={value} index={2}>
            <h2>Ajout d'un plan</h2>
            Développement en cours...
          </TabPanel>
          <TabPanel value={value} index={3}>
            <h2>Upload d'un tableau de garantie</h2>
            Développement en cours...
          </TabPanel>
          <TabPanel value={value} index={4}>
            <h2>Upload d'un tableau des prix</h2>
            Développement en cours...
          </TabPanel>
          <TabPanel value={value} index={5}>
            <h2>Documentation</h2>
            Développement en cours...
          </TabPanel>
        </div>
      </TableContainer>
    )
});

export default PlansUpdater;