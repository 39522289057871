import React, { memo, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { serversList } from '../constants';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EuroIcon from '@material-ui/icons/Euro';
import PayIcon from '@material-ui/icons/Payment';
import DashIcon from '@material-ui/icons/Dashboard';
import APIIcon from '@material-ui/icons/SettingsInputComponent';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ListCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Tooltip, Collapse, Box, Button } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Cached';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import BusinessIcon from '@material-ui/icons/Business';
import DomainDisabledIcon from '@material-ui/icons/DomainDisabled';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DNSIcon from '@material-ui/icons/DnsTwoTone';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';

import { LineChart, Line, CartesianGrid, XAxis, YAxis, PieChart, Pie, Sector, Cell, Legend } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerContainer: {
    overflow: 'auto',
  },
}));

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

const Dashboard = memo(props => {
    const classes = useStyles();

    let rows = props.rows;
    let dataPie = props.dataPie;
    console.log("rows",rows);
    const [data, setData] = useState([{name: 'Net profit', starter: 400, pro: 2400, trial: 2400},{name: 'Net profit', starter: 400, pro: 2400, trial: 2400}]);
    const [activePieSlice, setActivePieSlice] = useState(false);
    const [open, setOpen] = React.useState(false);

    function subtotal(items) {
      return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
    }
    
    let invoiceSubtotal = 0;
    if(rows!==undefined) subtotal(rows);
    const invoiceTotal = invoiceSubtotal;
    const RADIAN = Math.PI / 180;

    return(
      <div className={classes.root}>
        <TableContainer component={Paper}> 
        { dataPie !== undefined &&
        <div>      
          <span style={{fontSize: 25, fontWeight: 'bold', marginLeft: 170, marginTop: 20}}>Seats<span style={{marginLeft: 250}}>Profit</span></span>
          <PieChart width={800} height={200}>
          <Tooltip />
          <Pie
            data={dataPie}
            cx={200}
            cy={80}
            innerRadius={50}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
            label
          >
            {
              dataPie.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }
          </Pie>
          <Pie
            data={dataPie}
            cx={520}
            cy={80}
            innerRadius={50}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="profit"
            label
          >
            {
              dataPie.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }
            <Tooltip
            />
          </Pie>
        </PieChart>
        <PieChart width={800} height={30} onMouseEnter={()=>{setActivePieSlice(true)}} onMouseOut={()=>{setActivePieSlice(false)}}>
          <Pie isAnimationActive={false} data={dataPie} cx={200} cy={200} outerRadius={0} fill="#8884d8">
            {
              dataPie.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }  
          </Pie>
          <Legend/>
        </PieChart>
        </div>
        }
        <Table className={classes.table} aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={4}>
                Details
              </TableCell>
              <TableCell  align="right" colSpan={2}>Price</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>License</TableCell>
              <TableCell align="right">Clients</TableCell>
              <TableCell align="right">Seats</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Sum</TableCell>
            </TableRow>
          </TableHead>
          { rows!=undefined &&
          <TableBody>
              {rows.length===0&&
                <div style={{padding: 20}}>
                  <CircularProgress />
                </div>
              }
              { rows.map((row) => (
                <React.Fragment>
                  <TableRow key={row.desc}>
                    <TableCell>
                      <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell>{row.desc}</TableCell>
                    <TableCell align="right">{row.qty}</TableCell>
                    <TableCell align="right">{row.seat}</TableCell>
                    <TableCell align="right">{row.unit} €</TableCell>
                    <TableCell align="right">{ccyFormat(row.price)} €</TableCell>
                  </TableRow>
                  <TableRow colSpan={6} key={row.desc+"desc"} style={{ paddingBottom: 0, paddingTop: 0, background: '#eee', width: '100%'}}>
                      <TableCell colSpan={6} style={{width: '100%',paddingBottom: 0, paddingTop: 0}}>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Table size="small" aria-label="purchases" style={{fontFamily: 'monospace'}}>
                            <TableHead style={{background: '#ddd'}}>
                              <TableRow>
                                <TableCell>Seats / Clients</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.details.map((details, key) => (
                                <TableRow key={key}>
                                  <TableCell component="th" scope="row" style={{background: row.exp[key]>0?row.exp[key]>1?'#f66':'#ffd766':'#fff'}} >
                                    {details}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                      </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            <TableRow>
              <TableCell rowSpan={5} />
              <TableCell colSpan={4}>Net Profit</TableCell>
              <TableCell align="right">{ccyFormat(invoiceSubtotal)} €</TableCell>
            </TableRow>
          </TableBody>
          }
        </Table>
      </TableContainer>
    </div>
    )
});

export default Dashboard;